import React, { ChangeEvent, useEffect, useState } from "react"

import { ReactComponent as IconCross } from "@common_assets/svg/cross-circle.svg"
import {
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material"
import { useAppDispatch, useAppSelector } from "hooks/store"
import { useTranslation } from "react-i18next"
import { setTitle } from "store/adminTransaction/adminTransactions.slice"

import { selectTitle } from "../../../../../../store/adminTransaction/adminTransactions.selectors"

const Title = () => {
  const initialTitle = useAppSelector(selectTitle)
  const [titleText, setTitleText] = useState(initialTitle)
  const { t } = useTranslation()
  const theme = useTheme()
  const dispatch = useAppDispatch()

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTitleText(e.target.value)
  }

  useEffect(() => {
    setTitleText(initialTitle)
  }, [initialTitle])

  return (
    <Stack rowGap={1}>
      <Typography>{t("adminDashboard.titleIncluding")}</Typography>
      <TextField
        label={t("adminDashboard.title")}
        value={titleText}
        onChange={handleChange}
        onBlur={() => dispatch(setTitle(titleText))}
        InputProps={{
          endAdornment: !!titleText.length && (
            <InputAdornment
              position="end"
              sx={{ cursor: "pointer" }}
              onClick={() => {
                setTitleText("")
                dispatch(setTitle(""))
              }}
            >
              <IconCross style={{ color: theme.palette.mockup.primary60 }} />
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  )
}
export default Title
