import React, { useEffect, useState } from "react"

import { Stack, Typography } from "@mui/material"
import { Outlet } from "react-router"
import { useNavigate } from "react-router-dom"

import Dialog from "../../components/common/dialog/dialog"
import { useDialog } from "../../hooks/dialog"
import { RoutePath } from "../Route.types"

const AdminProtected = () => {
  const [isAdmin] = useState(true)
  const navigate = useNavigate()

  const { isOpen, handleOpen, handleClose } = useDialog()

  useEffect(() => {
    if (!isAdmin) {
      handleOpen()
    }
  }, [isAdmin])

  return (
    <>
      <div style={{ ...(!isAdmin && { filter: "blur(5px)" }) }}>
        <Outlet />
      </div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        onActionButtonClick={() => navigate(RoutePath.HOMEPAGE)}
      >
        <Stack>
          <Typography>Nu nu nu</Typography>
        </Stack>
      </Dialog>
    </>
  )
}
export default AdminProtected
