import React, { useEffect, useState } from "react"

import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Popover,
  Stack,
  ToggleButton,
  Typography,
  useTheme,
} from "@mui/material"
import {
  useMutationTeacherAdmin,
  useMutationTeacherManagementDelete,
} from "api/reactQuery/mutations/adminTeacher"
import {
  useMutationTeacherManagementAccept,
  useMutationTeacherManagementReject,
} from "api/reactQuery/mutations/teacherManagement"
import {
  useQueryTeacherManagementPending,
  useQueryTeachersManagement,
} from "api/reactQuery/queries/teacherAdmin"
import { TeachersManagement } from "api/reactQuery/queries/teacherAdmin.types"
import { ReactComponent as IconCheck } from "assets/common/svg/check.svg"
import { ReactComponent as IconCross } from "assets/common/svg/cross.svg"
import { ReactComponent as IconDots } from "assets/common/svg/threeDots.svg"
import BasicTable from "components/common/table/basicTable"
import { useIsBreakpointUp } from "hooks/breakpoint"
import { useDialog } from "hooks/dialog"
import { usePopover } from "hooks/popover"
import { useCustomSnackbar } from "hooks/snackbar"
import { useTranslation } from "react-i18next"
import { useInView } from "react-intersection-observer"
import { useQueryClient } from "react-query"
import { getErrorMessageFromTab } from "utils/api"

import Dialog from "../../../common/dialog/dialog"
import SearchbarForm from "../../../form/searchbarForm"

type TabNameTypes = ("active" | "pending") | null

const Teachers = () => {
  const { t } = useTranslation()
  const { showSnackbar } = useCustomSnackbar()
  const theme = useTheme()
  const [search, setSearch] = useState("")
  const [tabName, setTabName] = useState<TabNameTypes>("active")
  const [teacherProps, setTeacherProps] = useState<TeachersManagement | null>(
    null
  )
  const queryClient = useQueryClient()
  const { ref, inView } = useInView()
  const isTablet = useIsBreakpointUp("tablet")

  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: TabNameTypes
  ) => {
    if (newAlignment !== null) {
      setTabName(newAlignment)
    }
  }

  const { data, hasNextPage, fetchNextPage, isLoading } =
    useQueryTeachersManagement({
      skip: 0,
      limit: 20,
      filter: search.trim().length > 1 ? search.trim() : undefined,
      options: {
        onError: (err) => {
          showSnackbar({
            title: getErrorMessageFromTab(err),
            variant: "error",
          })
        },
      },
    })

  useEffect(() => {
    inView && fetchNextPage()
  }, [inView])

  const { handleClick, popoverAnchorEl, handleClose } =
    usePopover<HTMLDivElement>()

  const { handleClose: handleCloseDialog, handleOpen, isOpen } = useDialog()

  const { mutate } = useMutationTeacherAdmin({
    options: {
      onSuccess: () => {
        showSnackbar({
          title: "Admin rights have been successfully changed",
        })
        queryClient.invalidateQueries(["teachersManagement"])
        handleClose()
      },
    },
  })

  const {
    data: dataPending,
    hasNextPage: hasNextPagePending,
    fetchNextPage: fetchNextPagePending,
    isLoading: isLoadingPending,
  } = useQueryTeacherManagementPending({
    skip: 0,
    limit: 20,
    filter: search.trim().length > 1 ? search.trim() : undefined,
    options: {
      onError: (err) => {
        showSnackbar({
          title: getErrorMessageFromTab(err),
          variant: "error",
        })
      },
    },
  })

  const { mutate: deleteTeacher } = useMutationTeacherManagementDelete({
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries(["teachersManagement"])
        handleCloseDialog()
        showSnackbar({
          title: "Teacher has been deleted successfully",
        })
      },
      onError: (err) => {
        showSnackbar({
          title: getErrorMessageFromTab(err),
          variant: "error",
        })
      },
    },
  })

  const { mutate: acceptTeacher, isLoading: isLoadingAcceptTeacher } =
    useMutationTeacherManagementAccept({
      options: {
        onSuccess: () => {
          showSnackbar({
            title: "Teacher has been successfully added",
          })
          queryClient.invalidateQueries(["teachersManagementPending"])
        },
        onError: (err) => {
          showSnackbar({
            title: getErrorMessageFromTab(err),
            variant: "error",
          })
        },
      },
    })

  const { mutate: rejectTeacher, isLoading: isLoadingRejectTeacher } =
    useMutationTeacherManagementReject({
      options: {
        onSuccess: () => {
          showSnackbar({
            title: "Teacher has been successfully rejected",
          })
          queryClient.invalidateQueries(["teachersManagementPending"])
        },
        onError: (err) => {
          showSnackbar({
            title: getErrorMessageFromTab(err),
            variant: "error",
          })
        },
      },
    })

  /**
   * TODO(check) - wyłączyć hover na btn
   * TODO(check) - dodać sorting oraz sortBy
   */

  return (
    <Stack>
      <Stack flexDirection="row" columnGap={1}>
        <ToggleButton
          value="active"
          sx={{
            color:
              tabName === "active"
                ? theme.palette.mockup.primary60
                : theme.palette.mockup.neutral10,
            borderRadius: "8px 8px 0 0",
            borderBottom: "none",
            borderColor: theme.palette.mockup.primary95,
            backgroundColor:
              tabName === "active"
                ? theme.palette.common.white
                : theme.palette.mockup.primary95,
            width: "110px",
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
          onClick={handleAlignment}
        >
          {t("adminDashboard.active")}
        </ToggleButton>
        <ToggleButton
          onClick={handleAlignment}
          value="pending"
          sx={{
            color:
              tabName === "pending"
                ? theme.palette.mockup.primary60
                : theme.palette.mockup.neutral10,
            borderRadius: "8px 8px 0 0",
            borderBottom: "none",
            borderColor: theme.palette.mockup.primary95,
            backgroundColor:
              tabName === "pending"
                ? theme.palette.common.white
                : theme.palette.mockup.primary95,
            width: "110px",
            "&:hover": {
              backgroundColor: "inherit",
            },
          }}
        >
          {t("adminDashboard.pending")}
        </ToggleButton>
      </Stack>
      {tabName === "active" && (
        <>
          <BasicTable
            wrapperProps={{ borderRadius: "0 8px 8px 8px" }}
            topSlot={
              <Stack p={2}>
                <SearchbarForm
                  placeholder={t("adminDashboard.searchForTeachers")}
                  onSubmit={(data) => setSearch(data.search)}
                />
              </Stack>
            }
            head={
              <BasicTable.Row>
                <BasicTable.Cell sx={{ maxWidth: "100%" }}>
                  <BasicTable.HeadText>
                    {t("adminDashboard.teacher")}
                  </BasicTable.HeadText>
                </BasicTable.Cell>
                <BasicTable.Cell tableCellProps={{ width: "50px" }}>
                  <BasicTable.HeadText>
                    {t("adminDashboard.admin")}
                  </BasicTable.HeadText>
                </BasicTable.Cell>
                <BasicTable.Cell tableCellProps={{ width: "50px" }} />
              </BasicTable.Row>
            }
            body={data?.pages.flatMap((item) =>
              item.data.map((teacher) => (
                <BasicTable.Row key={teacher.id}>
                  <BasicTable.Cell>
                    <Stack
                      flexDirection="row"
                      columnGap={1}
                      alignItems="center"
                    >
                      <Avatar
                        src={teacher.avatar_url || ""}
                        alt={`${teacher.first_name}`}
                      />
                      <Stack>
                        <Typography>
                          {teacher.first_name} {teacher.last_name}
                        </Typography>
                        <Typography fontWeight="500" variant="body2">
                          {teacher.email}
                        </Typography>
                      </Stack>
                    </Stack>
                  </BasicTable.Cell>
                  <BasicTable.Cell sx={{ justifyContent: "center" }}>
                    {teacher.is_school_admin ? (
                      <Stack
                        sx={{
                          backgroundColor: theme.palette.mockup.successLight,
                          px: 1,
                          borderRadius: "25%",
                        }}
                      >
                        <Typography color="mockup.success50">YES</Typography>
                      </Stack>
                    ) : (
                      <Stack
                        sx={{
                          px: 1,
                          borderRadius: "25%",
                          backgroundColor: theme.palette.mockup.neutralWeak100,
                        }}
                      >
                        <Typography color="mockup.neutral10">NO</Typography>
                      </Stack>
                    )}
                  </BasicTable.Cell>
                  <BasicTable.Cell>
                    <Stack
                      onClick={(e) => {
                        handleClick(e)
                        setTeacherProps({ ...teacher })
                      }}
                      sx={{
                        borderRadius: "50%",
                        width: "30px",
                        height: "30px",
                        border: `2px solid ${theme.palette.mockup.primary95}`,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <IconDots
                        style={{
                          cursor: "pointer",
                          width: "20px",
                          height: "20px",
                        }}
                      />
                    </Stack>
                    <Popover
                      PaperProps={{
                        sx: {
                          boxShadow: "none",
                          borderColor: theme.palette.mockup.primary95,
                        },
                      }}
                      open={!!popoverAnchorEl}
                      anchorEl={popoverAnchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "center",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <Stack rowGap={2} p={2}>
                        <Typography
                          onClick={() => {
                            if (teacherProps) {
                              mutate({
                                is_school_admin: !teacherProps.is_school_admin,
                                teacher_id: teacherProps.id,
                              })
                            }
                          }}
                          variant="body2"
                          fontWeight="500"
                          sx={{
                            cursor: "pointer",
                            transition: "opacity 0.5s",
                            "&:hover": {
                              opacity: 0.7,
                            },
                          }}
                        >
                          {`${
                            teacherProps?.is_school_admin ? "Remove" : "Give"
                          } administrator rights `}
                        </Typography>
                        <Typography
                          onClick={handleOpen}
                          variant="body2"
                          fontWeight="500"
                          sx={{
                            cursor: "pointer",
                            transition: "opacity 0.5s",
                            "&:hover": {
                              opacity: 0.7,
                            },
                          }}
                        >
                          Delete teacher
                        </Typography>
                      </Stack>
                    </Popover>
                  </BasicTable.Cell>
                </BasicTable.Row>
              ))
            )}
            bottomSlot={
              <>
                {isLoading && (
                  <Box
                    component="span"
                    sx={{ display: "block", textAlign: "center", marginTop: 2 }}
                  >
                    <CircularProgress />
                  </Box>
                )}
                {!isLoading &&
                  !data?.pages?.flatMap((page) => page.data)?.length && (
                    <Typography m={2}>
                      No results matching the selected filtering criteria.
                    </Typography>
                  )}
                {hasNextPage && <div ref={ref} />}
              </>
            }
          />
          <Dialog
            open={isOpen}
            onClose={handleCloseDialog}
            titleText={`Are you sure you want to delete the teacher ${teacherProps?.first_name}
        ${teacherProps?.last_name}?`}
            onActionButtonClick={() => {
              if (teacherProps) deleteTeacher({ teacher_id: teacherProps.id })
            }}
          />
        </>
      )}
      {tabName === "pending" && (
        <Stack
          sx={{
            border: `1px solid ${theme.palette.mockup.primary95}`,
            borderTop: "none",
            borderBottom: "none",
          }}
        >
          <Stack
            p={2}
            sx={{
              backgroundColor: theme.palette.common.white,
              border: `1px solid ${theme.palette.mockup.primary95}`,
              borderRadius: "0 8px 0 0",
              borderBottom: "none",
            }}
          >
            <SearchbarForm
              placeholder={t("adminDashboard.searchForTeachers")}
              onSubmit={(data) => setSearch(data.search)}
            />
          </Stack>
          {dataPending?.pages.flatMap((item) =>
            item.data.map((teacher, index) => (
              <Stack
                key={teacher.id}
                justifyContent="space-between"
                flexDirection="row"
                alignItems="center"
                py={1}
                px={2}
                sx={{
                  backgroundColor:
                    index % 2 === 0
                      ? theme.palette.common.white
                      : theme.palette.mockup.primary99,
                }}
              >
                <Stack
                  flexDirection="row"
                  columnGap={1}
                  alignItems="center"
                  py={1}
                  sx={{ flex: 1, minWidth: 0 }}
                >
                  <Avatar
                    src={teacher.avatar_url || ""}
                    alt={`${teacher.first_name}`}
                  />
                  <Stack sx={{ minWidth: 0 }}>
                    <Typography noWrap>
                      {teacher.first_name} {teacher.last_name}
                    </Typography>
                    <Typography noWrap fontWeight="500" variant="body2">
                      {teacher.email}
                    </Typography>
                  </Stack>
                </Stack>
                {isTablet ? (
                  <Stack
                    flexDirection="row"
                    sx={{ maxHeight: "48px" }}
                    alignItems="center"
                  >
                    <Button
                      disabled={isLoadingRejectTeacher}
                      onClick={() => rejectTeacher({ teacherId: teacher.id })}
                      variant="text"
                    >
                      Reject
                    </Button>
                    <Button
                      disabled={isLoadingAcceptTeacher}
                      variant="outlined"
                      onClick={() => acceptTeacher({ teacherId: teacher.id })}
                    >
                      Accept
                    </Button>
                  </Stack>
                ) : (
                  <Stack flexDirection="row" columnGap={1}>
                    <Stack
                      onClick={() => rejectTeacher({ teacherId: teacher.id })}
                      sx={{
                        height: "32px",
                        width: "32px",
                        color: theme.palette.mockup.primary60,
                        borderRadius: "50%",
                        border: `2px solid ${theme.palette.mockup.primary90}`,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        pointerEvents: isLoadingRejectTeacher ? "none" : "auto",
                        opacity: isLoadingRejectTeacher ? 0.4 : 1,
                        cursor: isLoadingRejectTeacher ? "default" : "pointer",
                      }}
                    >
                      <IconCross style={{ width: "100%", height: "100%" }} />
                    </Stack>
                    <Stack
                      onClick={() => acceptTeacher({ teacherId: teacher.id })}
                      sx={{
                        height: "32px",
                        width: "32px",
                        color: theme.palette.mockup.primary60,
                        borderRadius: "50%",
                        border: `2px solid ${theme.palette.mockup.primary90}`,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        pointerEvents: isLoadingAcceptTeacher ? "none" : "auto",
                        opacity: isLoadingAcceptTeacher ? 0.4 : 1,
                        cursor: isLoadingAcceptTeacher ? "default" : "pointer",
                      }}
                    >
                      <IconCheck style={{ width: "100%", height: "100%" }} />
                    </Stack>
                  </Stack>
                )}
              </Stack>
            ))
          )}
          <Stack sx={{ backgroundColor: theme.palette.common.white }}>
            {isLoadingPending && (
              <Box
                component="span"
                sx={{ display: "block", textAlign: "center", marginTop: 2 }}
              >
                <CircularProgress />
              </Box>
            )}
            {!isLoadingPending &&
              !dataPending?.pages?.flatMap((page) => page.data)?.length && (
                <Typography m={2}>
                  No results matching the selected filtering criteria.
                </Typography>
              )}
            {hasNextPagePending && <div ref={ref} />}
          </Stack>
        </Stack>
      )}
    </Stack>
  )
}
export default Teachers
