import { Stack } from "@mui/material"
import { AxiosResponse } from "axios"
import { useQueryClient } from "react-query"
import { IUserMe } from "ts/interfaces/User"
import { isTeacher } from "utils/roleCheck"

import { AdminAside, ClassAside } from "./asideOptions"
import HelperOption from "./helperOption"
import TabNavigation from "./tabNavigation"

const Navigation = () => {
  const queryClient = useQueryClient()

  const isTeacherAdmin = () => {
    const cacheDataUserMe = queryClient.getQueryData<AxiosResponse<IUserMe>>([
      "userMe",
    ])
    if (cacheDataUserMe && isTeacher(cacheDataUserMe?.data.user)) {
      return cacheDataUserMe?.data.user.is_school_admin
    }
    return false
  }

  return (
    <Stack justifyContent="space-between" height="100%">
      <Stack flex="1 1 auto" component="nav">
        {isTeacherAdmin() ? (
          <TabNavigation
            render={(isAdmin) => (isAdmin ? <AdminAside /> : <ClassAside />)}
          />
        ) : (
          <ClassAside />
        )}
      </Stack>
      <HelperOption />
    </Stack>
  )
}

export default Navigation
